import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { getEnv } from 'state/next-env/selectors'
import { UniversalEnvs } from 'state/next-env/types'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

import { EnvsContext } from '../../contexts/EnvsProvider'

const useEnvs = (env: keyof UniversalEnvs) => {
  const envs = useContext(EnvsContext)

  if (!envs) throw new Error('useEnvs must be used within an EnvsProvider')

  const isNextEnvRefactorEnabled = useFeatureSwitch('next_env_slice_refactor')
  const envFromRedux = useSelector(getEnv(env))
  const envFromContext = envs[env]

  if (isNextEnvRefactorEnabled) return envFromContext

  return envFromRedux
}

export default useEnvs
